@import "partials/_variables";

.guided-tour-tool-tip-body {
  display: flex;
  flex-direction: column;

  &-step { 
    margin-bottom: 16px;
  }

  &-cta { 
    margin-top: 16px;
  }
}
