.primary-cta {
    height: 45px;
    width: 170px;
    max-width: 100%;
    margin: 24px auto 0;
    display: block;
    color: white;
    background-color: black;
    font-size: 0.9375rem;
    font-weight: 700;
    box-sizing: border-box;
    margin-bottom: 3vh;
}
