$mobile-max-width: 480px;
$small-tablet-min-width: 481px;
$small-tablet-max-width: 768px;
$tablet-min-width: 769px;
$tablet-max-width: 1024px;
$desktop-min-width: 1025px;
$desktop-max-width: 1512px;
$large-desktop-min-width: 1513px;

// This mixin provides flexible responsive breakpoint wrappers to apply styles to either 
// a single breakpoint span or a range

// Usage:
//
// .example {
//   // Applies to all screens $desktop-min-width and up
//   @include responsive-breakpoint(desktop, up) {
//     background-color: blue;
//   }
//
//   // Applies to screens of width $tablet-min-width to $tablet-max-width
//   @include responsive-breakpoint(tablet) {
//     background-color: red;
//   }
// }

@mixin responsive-breakpoint($breakpoint, $direction: none) {
  @if $breakpoint == mobile {
    @if $direction == up {
      @media (min-width: #{$mobile-max-width}) {
        @content;
      }
    } @else if $direction == down {
      @media (max-width: #{$mobile-max-width}) {
        @content;
      }
    } @else if $direction == none {
      @media (max-width: #{$mobile-max-width}) {
        @content;
      }
    }
  } @else if $breakpoint == small-tablet {
    @if $direction == up {
      @media (min-width: #{$small-tablet-min-width}) {
        @content;
      }
    } @else if $direction == down {
      @media (max-width: #{$small-tablet-max-width}) {
        @content;
      }
    } @else if $direction == none {
      @media (min-width: #{$small-tablet-min-width}) and (max-width: #{$small-tablet-max-width}) {
        @content;
      }
    }
  } @else if $breakpoint == tablet {
    @if $direction == up {
      @media (min-width: #{$tablet-min-width}) {
        @content;
      }
    } @else if $direction == down {
      @media (max-width: #{$tablet-max-width}) {
        @content;
      }
    } @else if $direction == none {
      @media (min-width: #{$tablet-min-width}) and (max-width: #{$tablet-max-width}) {
        @content;
      }
    }
  } @else if $breakpoint == desktop {
    @if $direction == up {
      @media (min-width: #{$desktop-min-width}) {
        @content;
      }
    } @else if $direction == down {
      @media (max-width: #{$desktop-max-width}) {
        @content;
      }
    } @else if $direction == none {
      @media (min-width: #{$desktop-min-width}) and (max-width: #{$desktop-max-width}) {
        @content;
      }
    }
  } @else if $breakpoint == large-desktop {
    @if $direction == up {
      @media (min-width: #{$large-desktop-min-width}) {
        @content;
      }
    } @else if $direction == down {
      // Typically, there is no upper limit for large desktop, but you can define one if needed.
      @media (min-width: #{$desktop-max-width}) {
        @content;
      }
    } @else if $direction == none {
      @media (min-width: #{$large-desktop-min-width}) {
        @content;
      }
    }
  }
}
