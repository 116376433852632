@import "partials/_variables";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.sizeLabel {
  margin: 50px 0 12px 0; 
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 500;
  }
}

.button {
  padding: 8px 16px;
  border: 1px solid $color-border-opaque;
  border-radius: 6px;
  cursor: pointer;
}

.button:hover {
  background-color: #F0F0F0; // Temporary hover state value
}

.selected {
  background-color: $color-eggplant100;
  color: $color-burgundy;
  border-color: $color-burgundy;

  &:hover {
    background-color: $color-eggplant100;
  }
}
