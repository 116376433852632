@import "partials/_variables";

.container {
  position: relative;
}

.total-images {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.5);
  box-shadow: 2px 2px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 38px;
  margin-left: 17px;
  padding: 4px 10px;
  position: absolute;
  top: -45px;
  
  .character {
    width: 11px;
    text-align: center;
    color: $color-content-secondary;
  }

  .separator {
    border-left: 1px solid $color-content-secondary;
    width: 4px;
    height: 11px;
  }

  .character-left {
    padding-right: 4px
  }
}
