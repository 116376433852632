@import "../../../../../stylesheets/design-system/layout/responsive-breakpoint-mixins";

.review-thumbnail-carousel {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none; // Hide scrollbar on Firefox

  // Hide scrollbar on Webkit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  img {
    height: 120px;
  }

  .review-thumbnail-carousel__child {
    margin-right: 2px;
  }

  @include responsive-breakpoint(mobile) {
    margin: 24px -16px 0 0;
  }

  @include responsive-breakpoint(small-tablet) {
    margin: 24px -80px 0 0;
  }

  @include responsive-breakpoint(tablet, up) {
    margin: 16px -56px 0 0;
  }
}
